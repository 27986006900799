<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_head-title">MY LESSONS</div>
        </div>
        <div class="page_content">
            <div class="page_title">Welcome {{logUserData.name}}</div>
            <div class="page_subtitle" v-if="activePhrase">{{activePhrase.title || ''}}</div>
            <div class="page_content-recent_lessons">
                <div class="recent_title">Lessons</div>
                <div v-if="my_lessons.length">
                    <div v-for="lesson of my_lessons" class="lesson_item">
                        <div class="lesson_item-data">
                            <div class="lesson_item-date">{{getDate(lesson.created_at)}}</div>
                            <div class="lesson_item-title">{{lesson.lesson_title}}</div>
                            <div class="lesson_item-name">{{lesson.user.name}}&nbsp; {{lesson.user.name}}</div>
                        </div>
                        <div class="edit_delete-container">
                            <div class="edit_btn" @click="editLesson(lesson)"><div class="edit_icon"></div>Edit</div>
                            <div class="delete_btn" id="delete_btn" @click="deleteModal(lesson)"><div class="delete_icon"></div>Delete</div>
                        </div>
                        <div class="">
                            <div v-for="team of lesson.team" class="lesson_item-team">{{team.team_name}}</div>
                        </div>
                        <div class="lesson_item-line"></div>
                    </div>
                  <div class="mt-3">
                    <b-pagination
                        v-model="currentPage"
                        pills
                        :total-rows="total_rows"
                        :per-page="perPage"
                        @change="changePage"
                    ></b-pagination>
                  </div>
                </div>
                <div v-else class="no_lessons">No Lessons yet</div>

            </div>

<!--            <div class="page_cards">-->
<!--                <div class="flip-card" v-for="card in my_lessons" :key="card.id">-->
<!--                    <div class="flip-card-inner">-->
<!--                        <div class="flip-card-front">-->
<!--                            <div>{{card.lesson_title}}</div>-->
<!--                        </div>-->
<!--                        <div class="flip-card-back">-->
<!--                            <img src="@/assets/images/logo_small.svg" alt="Avatar">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <!--delete lesson modal-->
        <b-modal ref="delete_lesson-modal" centered size="md" hide-header hide-footer>
            <div class="modal_content">
                <div class="modal_title">
                    Are you sure you want to permanently remove this lesson?
                </div>
                <div class="modal_btn">
                    <b-button class="cancel_btn mx-2" @click="deleteModal()">Cancel</b-button>
                    <b-button class="add_btn mx-2" @click="removeLesson()">Delete</b-button>
                </div>

            </div>
        </b-modal>
        <!--delete lesson modal-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "MyLessons",
        data() {
            return {
                activePhrase: null,
                removeLessonId: null,
                my_lessons: [],
                currentPage: 1,
                perPage: 3,
                total_rows: 1
            }
        },
        computed: {
            ...mapGetters(['logUserData'])
        },
        created() {
            this.getAllPhrases()
            this.getLessons(1)
        },
        methods: {
            ...mapActions(['getAllLessons', 'getPhrases', 'deleteLesson']),
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            },
            getLessons(page) {
                this.getAllLessons(page)
                    .then(res => {
                        this.my_lessons = res.data.data
                    })
                    .catch(err => {
                        console.log(err, 'error')
                    })
            },
            getAllPhrases() {
                this.getPhrases()
                    .then((res) => {
                        this.activePhrase = res.data[Math.floor(Math.random()*res.data.length)]
                    })
            },
            deleteModal(lesson) {
                if(lesson) {
                    this.removeLessonId = lesson.id
                }
                this.$refs['delete_lesson-modal'].toggle('#delete_btn')
            },
            removeLesson() {
                this.deleteLesson(this.removeLessonId)
                .then((res) => {
                    this.removeLessonId = null
                    this.deleteModal()
                    this.getLessons(1)
                    this.currentPage = 1
                })

            },
            editLesson(lesson) {
                this.$router.push({path: `/lesson_edit/${lesson.id}`})
            },
          changePage(ev) {
            this.getLessons(ev)
          }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
    }
    &_head {
        width: 100%;
        height: 73px;
        background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        position: sticky;
        top: 0;
        z-index: 3;
        &-title {
            color: #FFFFFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 10px;
        &-recent_lessons {
            padding: 20px 100px;
            width: 100%;
          ::v-deep .pagination {
            & .page-item {
              &.active {
                & .page-link {
                  color: #ffffff;
                  background: #00A99D;
                  &:focus {
                    box-shadow: 0 0 5px 0.1rem #00A99D;
                  }
                }
              }
              & .page-link {
                color: #11334D;
                border-color: rgba(17, 51, 77, 0.2);
              }
            }
          }
            & .no_lessons {
                color: #11334D;
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
                line-height: normal;
            }
            & .recent_title {
                color: #11334D;
                font-size: 22.677px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 20px 0;
            }
            & .lesson_item {
                position: relative;
                margin: 15px 0;
                max-width: 800px;
                width: 100%;
                height: 130px;
                border-radius: 12px;
                border: 1px solid #11334D;
                padding: 10px  50px 10px 20px;
                display: flex;
                justify-content: space-between;
                &:hover {
                    .edit_delete-container {
                        opacity: 1;
                        visibility: visible;
                        transition: .4s;
                    }
                }
                & .edit_delete-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    visibility: hidden;
                    transition: .4s;
                    & .edit {
                        &_btn {
                            color: #00A99D;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin: 8px 0;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        }
                        &_icon {
                            margin-right: 10px;
                            width: 34px;
                            height: 34px;
                            background: url("~@/assets/icons/edit_btn.svg") no-repeat center;
                            background-size: contain;

                        }
                    }
                    & .delete {
                        &_btn {
                            color: #C42E25;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin: 8px 0;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        }
                        &_icon {
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                            background: url("~@/assets/icons/deactive.svg") no-repeat center;
                            background-size: contain;

                        }
                    }
                }
                &-data {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                }
                &-date {
                    color: rgba(17, 51, 77, 0.60);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                &-title {
                    color: #11334D;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 5px 0;
                }
                &-name {
                    color: rgba(17, 51, 77, 0.60);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                &-team {
                    color: #11334D;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 2px 0;
                }
                &-line {
                    position: absolute;
                    right: 12px;
                    top: 0;
                    width: 10px;
                    height: 100%;
                    background: #00A99D;
                    border-left: 2px dotted #003761;
                }
            }
        }
    }
    &_title {
        color: #11334D;
        font-size: 38.059px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &_subtitle {
        color: #11334D;
        font-size: 38.059px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
    }
    &_cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 50px;
        .flip-card {
            margin: 23px 27px;
            background-color: transparent;
            width: 236px;
            height: 200px;
            perspective: 1000px;
        }

        .flip-card-inner {
            position: relative;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        }

        .flip-card:hover .flip-card-inner {
            transform: rotateX(180deg);
        }

        .flip-card-front, .flip-card-back {
            position: absolute;
            border-radius: 12px;
            filter: drop-shadow(0px 4px 12px #004475);
            border: 5px solid #00A99D;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .flip-card-front {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #004475;
            color: #ffffff !important;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .flip-card-back {
            width: 100%;
            height: 100%;
            background-color: #00A99D;
            transform: rotateX(180deg);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal {
    &_content {
        padding: 10px 20px;
    }

    &_title {
        color: #11334D;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 10px;
    }

    &_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 30px 0 10px;

        & .cancel_btn,
        & .add_btn {
            height: 40px;
            border-radius: 12px;
            border: 1px solid #FFFFFF;
            background: #00A99D;
            color: #FFFFFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 20px;
            cursor: pointer;
        }
    }
}
</style>
